<template>
  <div class="c-app">
    <Sidebar />
    <CWrapper>
      <Header />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <router-view></router-view>
          </CContainer>
        </main>
      </div>
      <Footer />
    </CWrapper>
  </div>
</template>

<script>
import Sidebar from "./partials/accountant/Sidebar";
import Header from "./partials/management/Header";
import Footer from "./partials/management/Footer";

export default {
  components: {
    Sidebar,
    Header,
    Footer
  }
};
</script>

<style lang="scss">
@import "~@coreui/coreui/dist/css/coreui.min.css";
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.loader {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
  padding-top: 200px;
}
</style>
